import '../../../../node_modules/vanilla-cookieconsent/src/cookieconsent';

// Init cookie consent
const cc = initCookieConsent();

cc.run({
  current_lang: document.documentElement.getAttribute('lang'),
  cookie_expiration: 365,
  onAccept: () => {
    // Do something
  },
  languages: {
    nl: {
      consent_modal: {
        title: 'Health Valley gebruikt cookies',
        description: 'om je een optimale gebruikerservaring te bieden.',
        primary_btn: {
          text: 'Accepteren',
          role: 'accept_all' //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Instellingen',
          role: 'settings' //'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Cookie instellingen',
        save_settings_btn: "Save instellingen",
        accept_all_btn: "Alles accepteren",
        close_btn_label: "Sluiten",
        blocks: [
          {
            title: "Cookie gebruik",
            description: 'Wij gebruiken cookies om je een optimale gebruikerservaring te bieden. Op de pagina <a href="https://www.healthvalley.nl/nl/cookieverklaring/">cookieverklaring</a> leest u wat cookies zijn, voor welke doeleinden wij deze gebruiken en welke cookies wij precies plaatsen.'
          },
          {
            title: "Functionele cookies",
            description: 'Wij gebruiken functionele cookies om een optimale gebruikerservaring te bieden bij het bezoeken van onze website. Tevens zijn deze cookies noodzakelijk voor een goede technische werking van onze website.',
            toggle: {
              value: 'necessary',
              enabled: false,
              readonly: true
            }
          },
          {
            title: "Analytische cookies",
            description: 'Wij gebruiken daarnaast ook analytische cookies (van Google Analytics) om ons inzicht te geven in de informatiebehoefte en interesse van onze bezoekers.',
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false
            }
          }
        ]
      }
    }
  },
  gui_options: {
    consent_modal: {
      transition: 'slide'
    },
    settings_modal: {
      transition: 'slide'
    }
  }
});
