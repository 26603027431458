import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function headerSlider() {
  const element = document.getElementById('js-header-slider');
  if (element) {
    return tns({
      container: '#js-header-slider',
      items: 1,
      controlsContainer: false,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      arrowKeys: true,
      speed: 800,
      autoplay: true,
      autoplayButton: false,
      autoplayHoverPause: true
    });
  }
}

headerSlider();
